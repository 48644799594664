// for super admin

const SuperAllVariable = ["$FirstName$","$LastName$"];
const ConfirmationEmail = ["$ActivateUrl$"];
const WelcomeEmail = ["$LoginUrl$", "$OutbooksTitle$"];
const ForgotPasswordEmail = ["$ResetPasswordUrl$"];
const InviteForSignUp = ["$ActivateUrl$", " $AppUrl$"];
const TwoFA = ["$VerificationCode$", "$OutbooksTitle$"];

export default {
  ConfirmationEmail,
  WelcomeEmail,
  ForgotPasswordEmail,
  InviteForSignUp,
  TwoFA,
  SuperAllVariable,
};
