import { Base_Url } from "../../../Base-Url/Base_Url";
import { Template, TemplateAvailable } from "../../../Database/ProposalToolDatabase";
import {
    getListWithAuthenticated,
    postApiWithAuthenticated,
} from "../../reducer/reduxService";

//Template Base Url
const TemplateBaseUrl = `${Base_Url}/Template`;
const TemplateBaseUrlForPdf = `${Base_Url}/ImportTemplatePDF`;
const TemplatePDFBaseUrl = `${Base_Url}/AwsS3`;
 
 
 
 
 
 
 export const GetEmailTemplateListLookupList = async (organisationKeyID,userKeyID,TemplateTypeCatID,TemplateTypeID) => {
    let url = `/GetTemplateLookupList?UserKeyID=${userKeyID}&TemplateTypeCatID=${TemplateTypeCatID}&TemplateTypeID=${TemplateTypeID}`

    const res = await getListWithAuthenticated(
        TemplateBaseUrl + url,
    );
    if(organisationKeyID){
        url += `&organisationKeyID=${organisationKeyID}`
    }
   
    return res;
};